.App {
  text-align: center;
  /* background-color: #282c34; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 1px solid  #f1eded;
   /* box-shadow: 0 2px 1rem rgba(0,0,0,0.1); */
}
.App-link {
  color: #61dafb;
}
.unsub{margin: 5rem auto; border: 1px solid #ddd; border-top: 5px solid #6bac22; max-width: 600px; padding: 4rem 2rem; border-radius: 4px; box-shadow: 0 0 1rem rgba(0,0,0,0.2);}
.msg{font-size: 1.5rem; }
.footer{
  display: flex; align-items: center; justify-content: space-between; background-color: #39729b; padding: 1.5rem 3rem; color: #fff;
}
.footer a{color: #fff; }
.footer a:hover{text-decoration: none;}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
